import {
    createApp,
    ref,
    computed,
    reactive,
    onMounted,
    onUnmounted,
    nextTick,
    watch
} from 'https://unpkg.com/vue@3.5.11/dist/vue.esm-browser.js';  // 來取代cdn方式
import * as XLSX from 'xlsx';

if (typeof window.csrfToken === 'undefined') {
    window.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}

const urlParams = new URLSearchParams(window.location.search);
const apiUrl = `${window.location.origin}${window.location.pathname}`;

createApp({
    setup() {
        const points = ref(0);
        const smsSubject = ref("");
        const smsContent = ref("");
        const isLoading = ref(false);

        const writeList = ref("listsByExcel");
        // 發送時程
        const sendNow = ref(true);
        const sendBySchedule = ref(false);
        const selectedDatetime = ref("");
        const schedule = ref(false);
        const rowsToAdd = ref(5);
        const parsedData = ref([]);
        const employeeId = '{{ $employeeId }}';

        // 時間轉換
        const formatToCustomDate = (dateString) => {
            const dateObject = new Date(dateString);
            if (isNaN(dateObject.getTime())) {
                return "";
            }
            return [
                dateObject.getFullYear(),
                String(dateObject.getMonth() + 1).padStart(2, "0"),
                String(dateObject.getDate()).padStart(2, "0"),
                String(dateObject.getHours()).padStart(2, "0"),
                String(dateObject.getMinutes()).padStart(2, "0"),
            ].join("");
        };

        const debounce = (func, delay) => {
            let timeoutId;
            return (...args) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    func(...args);
                }, delay);
            };
        };

        function countCharacters(text) {
            // 將 Windows 換行符 \r\n 轉成單一 \n，確保計算正確
            text = text.replace(/\r\n/g, "\n");

            // 回傳字元數 (包括換行符)
            return text.length;
        }

        // 定義 funSmsCount 函數，處理所有的扣點邏輯
        function funSmsCount(length) {
            let points = 0;

            if (length >= 1 && length <= 70) {
                points = 1; // 1~70字扣1點
            } else if (length >= 71 && length <= 134) {
                points = 2; // 71~134字扣2點
            } else if (length >= 135 && length <= 201) {
                points = 3; // 135~201字扣3點
            } else if (length >= 202 && length <= 268) {
                points = 4; // 202~268字扣4點
            } else if (length >= 269 && length <= 333) {
                points = 5; // 269~333字扣5點
            } else if (length > 333) {
                // 超過333字，遞迴計算
                points = 5 + funSmsCount(length - 333);
            }

            return points;
        }

        const smsCount = computed(() => funSmsCount(countCharacters(smsContent.value)));

        const saveState = reactive({
            commonMessages: {},
            signatures: {},
            parsedData: parsedData,
        });

        const insertPlaceholder = (placeholder) => {
            smsContent.value += placeholder;
        };

        const isCollapse = reactive({
            1: false,
            2: false,
        });
        const toggleCollapse = (index) => {
            isCollapse[index] = !isCollapse[index];
        };

        /**
         * 切換標間
         *
         * @param tabId
         */
        const setActiveTab = (tabId) => {
            if (tabId == 'listsByGroupsSelect') { // 是否點選 [連絡人(群組)選取]
                showGroup(''); // 預設顯示所有群組
            }else{
                showGroup(0); // 預設顯示常用聯絡人
            }
            selectAllCommonlyUsedData(null, false);
            writeList.value = tabId;
        };

        // 處理POST json
        const updateItemState = (type, action, id) => {
            let body = {};
            let url = '';
            let json = null;
            switch (action + '_' + type) {
                case "get_groups":
                    url = apiUrl + '/get_groups';
                    body = {};
                    json = response(url, body, type, action, null);
                    break;
                case "get_contacts":
                    url = apiUrl + '/get_contacts';
                    body = {};
                    json = response(url, body, type, action, null) || [];
                    break;
                case "get_commonMessages":
                    url = apiUrl + '/get_commonMessages';
                    body = {};
                    json = response(url, body, type, action, null);
                    break;
                case "add_commonMessages":
                    url = apiUrl + '/add_commonMessages';
                    body = {
                        'title': smsSubject.value.trim(),
                        'text': smsContent.value.trim(),
                    };
                    json = response(url, body, type, action, id);
                    break;
                case "edit_commonMessages":
                    if (saveState[type][id].isEditing) {
                        if (id !== -1) {
                            url = apiUrl + '/edit_commonMessages';
                            body = {
                                'id': id,
                                'title': saveState[type][id].title.trim(),
                                'text': saveState[type][id].text.trim(),
                            };
                            json = response(url, body, type, action, id);
                        }
                    } else {
                        setData(type, action, id, json);
                    }
                    break;
                case "del_commonMessages":
                    if (id !== -1) {
                        url = apiUrl + '/del_commonMessages';
                        body = {
                            'id': id,
                        };
                        json = response(url, body, type, action, id);
                    }
                    break;
                case "select_commonMessages":
                    if (id !== -1) {
                        console.log(saveState[type][id].isEditing);
                        if (!saveState[type][id].isEditing) {
                            smsSubject.value = saveState[type][id].title;
                            smsContent.value = saveState[type][id].text;
                        }
                    }
                    break;

                case "get_signatures":
                    url = apiUrl + '/get_signatures';
                    body = {};
                    json = response(url, body, type, action, null);
                    break;
                case "add_signatures":
                    url = apiUrl + '/add_signatures';
                    body = {
                        'text': smsContent.value.trim(),
                    };
                    json = response(url, body, type, action, id);
                    break;
                case "edit_signatures":
                    if (saveState[type][id].isEditing) {
                        if (id !== -1) {
                            url = apiUrl + '/edit_signatures';
                            body = {
                                'id': id,
                                'text': saveState[type][id].text.trim(),
                            };
                            json = response(url, body, type, action, id);
                        }
                    } else {
                        setData(type, action, id, json);
                    }
                    break;
                case "del_signatures":
                    if (id !== -1) {
                        url = apiUrl + '/del_signatures';
                        body = {
                            'id': id,
                        };
                        json = response(url, body, type, action, id);
                    }
                    break;
                case "select_signatures":
                    if (id !== -1) {
                        if (!saveState[type][id].isEditing) {
                            smsContent.value += saveState[type][id].text;
                        }
                    }
                    break;
            }
        };

        // json 資料處理
        const setData = (type, action, id, json) => {
            switch (action + '_' + type) {
                case 'get_contacts': // 取得聯絡人
                    listsByCommonlyUsed.value = json.data;
                    performFilterList();
                    break;
                case 'get_groups': // 取得群組
                    groupNames.value = json.data;
                    break;
                case 'get_commonMessages': // 取得常用簡訊
                    saveState.commonMessages = json.data.reduce((data, item) => {
                        data[item.id] = {
                            id: item.id,
                            title: item.title,
                            text: item.text,
                            isEditing: false
                        };
                        return data;
                    }, {});
                    break;
                case 'get_signatures': // 取得簽名檔
                    saveState.signatures = json.data.reduce((data, item) => {
                        data[item.id] = {
                            id: item.id,
                            text: item.text,
                            isEditing: false
                        };
                        return data;
                    }, {});
                    break;
                case "add_commonMessages":  // 新增常用簡訊
                    saveState[type][id] = {
                        id: id,
                        title: smsSubject.value.trim(),
                        text: smsContent.value.trim(),
                        isEditing: false,
                    };
                    break;
                case "add_signatures":  // 新增簽名檔
                    saveState[type][id] = {
                        id: id,
                        text: smsContent.value.trim(),
                        isEditing: false,
                    };
                    break;
                case "edit_commonMessages": // 取得常用簡訊
                case "edit_signatures": // 取得簽名檔
                    if (id !== -1) {
                        saveState[type][id] = {
                            ...saveState[type][id],
                            isEditing: !saveState[type][id].isEditing
                        };
                    }
                    break;
                case "del_commonMessages":  // 刪除常用簡訊
                case "del_signatures":  // 刪除簽名檔
                    if (id !== -1) {
                        delete saveState[type][id];
                    }
                    break;
            }
        }

        // 處理POST
        let response = (url, body, type, action, id) => {
            return fetch(url, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-TOKEN': csrfToken,
                    'Accept': 'application/json',
                },
                method: 'POST',
                body: new URLSearchParams(body),
            }).then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        // 將錯誤訊息拋出
                        throw new Error(JSON.stringify(json));
                    });
                }
                return response.json(); // 轉換回應為 JSON 格式
            }).then((json) => {
                setData(type, action, json.id ?? null, json);
                return json;
            }).catch((error) => {
                try {
                    const errorObj = JSON.parse(error.message); // 將錯誤的字串解析為物件
                    if (errorObj.errors) {
                        if (typeof errorObj.errors === "object" && errorObj.errors !== null) {
                            // 如果是物件，將所有錯誤訊息轉換為陣列後顯示
                            Object.values(errorObj.errors).flat().forEach(message => {
                                $.notify(message, {type: 'danger'});
                            });
                        } else if (Array.isArray(errorObj.errors)) {
                            // 如果有 errors，顯示每個錯誤訊息
                            errorObj.errors.forEach(message => {
                                $.notify(message, {type: 'danger'});
                            });
                        } else {
                            // 否則顯示通用錯誤訊息
                            $.notify(errorObj.errors, {type: 'danger'});
                        }
                    } else {
                        // 否則顯示通用錯誤訊息
                        $.notify(errorObj.message, {type: 'danger'});
                    }
                } catch (e) {
                    console.log(e)
                    // 如果無法解析錯誤，顯示通用的錯誤訊息
                    $.notify('資料錯誤', {type: 'danger'});
                }
            });
        }

        // 手動填寫收件者名單
        function handleAddBlankRows() {
            addRowsToParsedData(this.rowsToAdd);
        }

        const addRowsToParsedData = (rowsToAdd = 5) => {
            const newRows = Array.from({length: rowsToAdd});
            newRows.forEach(() => {
                parsedData.value.push({
                    id: null,
                    name: "",
                    mobile: "",
                    job: "",
                    group: [],
                    isEditing: false,
                });
            });
        };

        /**
         * 載入大量名單的導入流程
         */
            // 上傳大量簡訊
        const uploadFile = ref(null);
        // 計算欄寬
        const calculateColWidths = (data) => {
            const maxLengths = data[0].map((_, colIndex) =>
                Math.max(...data.map((row) => (row[colIndex] || "").toString().length))
            );
            return maxLengths.map((len) => ({wch: Math.max(len, 10)}));
        };
        const letterExample = () => {
            const emptySheetData = [["姓名", "手機門號", "職稱"]];
            const exampleSheetData = [
                ["姓名", "手機門號", "職稱"],
                ["王小明", "0912345678", "經理"],
                ["李小華", "0922333444", "副經理"],
            ];
            const exampleSheet = XLSX.utils.aoa_to_sheet(exampleSheetData);
            const emptySheet = XLSX.utils.aoa_to_sheet(emptySheetData);

            emptySheet["!cols"] = calculateColWidths(emptySheetData);
            exampleSheet["!cols"] = calculateColWidths(exampleSheetData);
            emptySheet["!cols"][1] = {wch: 15, z: "@"};
            exampleSheet["!cols"][1] = {wch: 15, z: "@"};

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, emptySheet, "發送清單");
            XLSX.utils.book_append_sheet(workbook, exampleSheet, "大量發送範例檔");

            const excelBuffer = XLSX.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });

            const blob = new Blob([excelBuffer], {
                type: "application/octet-stream",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "大量發送範例檔.xlsx";
            link.click();
        };
        // 解析 excel
        const lastUploadedFile = ref(null);
        const handleFileUpload = (event) => {
            const fileInput = event.target;
            const file = event.target.files[0];
            isLoading.value = true;
            console.log("event", event.target);

            if (
                lastUploadedFile.value &&
                lastUploadedFile.value.name === file.name &&
                lastUploadedFile.value.lastModified === file.lastModified &&
                lastUploadedFile.value.size === file.size
            ) {
                Swal.fire({
                    title: "Oops...",
                    text: "您已上傳相同的檔案，請選擇其他檔案！",
                    icon: "error",
                });
                isLoading.value = false;
                return;
            }

            lastUploadedFile.value = {
                name: file.name,
                lastModified: file.lastModified,
                size: file.size,
            };

            if (file) {
                console.log("file", file);
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, {type: "array"});
                    console.log("workbook", workbook);

                    const firstSheetName = workbook.SheetNames[0];
                    console.log("firstSheetName", firstSheetName);

                    if (firstSheetName !== "發送清單") {
                        console.log("發送清單");
                        Swal.fire({
                            title: "Oops...",
                            text: "資料格式不正確，請下載大量發送範例檔!",
                            icon: "error",
                        }).then(() => {
                            isLoading.value = false;
                            fileInput.value = "";
                        });
                        return;
                    }
                    const worksheet = workbook.Sheets[firstSheetName];
                    console.log("worksheet", worksheet);
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});
                    console.log("jsonData", jsonData);
                    parseData(jsonData);

                    fileInput.value = "";
                    setTimeout(() => {
                        isLoading.value = false;
                    }, 500);
                };
                reader.readAsArrayBuffer(file);
            } else {
                isLoading.value = false;
            }
        };
        // 大量簡訊的 excel 去掉 header
        const parseData = (data) => {
            console.log("data", data);
            const headers = data[0];
            console.log("headers", headers);
            const rows = data.slice(1).map((row) => {
                const obj = {};
                headers.forEach((header, index) => {
                    obj[header.trim()] = row[index] ? row[index].trim() : "";
                });
                console.log("obj", obj);
                return obj;
            });
            console.log("rows", rows);
            parsedData.value.splice(0, parsedData.value.length, ...rows);
            console.log("parsedData", parsedData.value);
            setTimeout(() => {
                isLoading.value = false;
                setActiveTab("listsByManual");
            }, 1000);
        };

        /**
         * 常用聯絡人的導入流程
         */
        const listsByCommonlyUsed = ref([]);    // 常用聯絡人列表
        let selectedCommonlyUsed = ref([]);     // 選取的聯絡人列表
        let searchKeyword = ref("");            // 篩選的關鍵字
        const keyword = ref("");            // 篩選的關鍵字
        const filteredList = ref([]);           // 篩選後的聯絡人列表
        const timeoutId = ref(null);            // 延遲暫存
        const selectAll = ref([false, false]);
        const hasGroup = reactive({});

        /**
         * 選取所有聯絡人
         *
         * @param group_id
         * @param type
         */
        const selectAllCommonlyUsedData = (group_id = null, isClick = true) => {
            if(!group_id && group_id !== 0){
                group_id = selectedGroup.value;
            }

            if(isClick){
                if(group_id === 0){
                    selectAll.value[0] = !selectAll.value[0];
                }else{
                    selectAll.value[1] = !selectAll.value[1];
                }
            }else{
                selectAll.value = [false, false];
            }

            const selectedValue = selectAll.value[group_id === 0 ? 0 : 1];

            let i = 0;
            let j = 0;

            groupNames.value.forEach(group => {
                hasGroup[group.id] = false;  // 這樣 Vue 會追蹤變化
            });

            listsByCommonlyUsed.value.forEach(item => {
                if(group_id === '' || (Array.isArray(item.group) && item.group.includes(group_id))){
                    i++;
                    if(isClick){    // 如果是選擇 全選(全消)，群組內的聯絡人都全選(全消)
                        item.isSelected = selectedValue;
                    }else if(item.isSelected){  // 統計選取的群組聯絡人數量
                        j++;
                    }
                }

                // 選取的聯絡人相關群組 icon 都變色
                if (item.isSelected === true && Array.isArray(item.group)) {
                    item.group.forEach(group_id => {
                        if (!hasGroup[group_id]) {
                            hasGroup[group_id] = false;
                        }
                        hasGroup[group_id] = true;
                    });
                }
            })

            handleCommonlyUsedData(false);

            if(!isClick){ // 不是溝"全選"
                if(i !== 0 && i == j){  // 選取的群組聯絡人跟總數一樣
                    if(group_id !== 0){
                        selectAll.value[1] = true;  // 群組聯絡人"全選"打勾
                    }else{
                        selectAll.value[0] = true;  // 常用聯絡人"全選"打勾
                    }
                }
            }
        }

        const search = () => {
            keyword.value = searchKeyword.value;
            performFilterList();
        }
        /**
         * 篩選常用聯絡人列表
         */
        const performFilterList = () => {
            const isEmptyKeyword = !keyword.value;
            const group = selectedGroup.value;

            filteredList.value = [];
            filterListsByGroup.value = [];
            listsByCommonlyUsed.value.forEach(item => {
                const matchesKeyword = isEmptyKeyword || [item.name, item.mobile, item.job].some(field => field.includes(keyword.value.trim()));
                const matchesCommonly = Array.isArray(item.group) && item.group.includes(0);
                const matchesGroup = group === '' || (Array.isArray(item.group) && item.group.includes(group));

                if (matchesCommonly && matchesKeyword) {
                    filteredList.value.push(item);
                }

                if (matchesGroup && matchesKeyword) {
                    filterListsByGroup.value.push(item);
                }

            })

        };

        // 篩選常用聯絡人列表
        const handleFilterList = debounce(search, 300);

        /**
         * 計算常用聯絡人選取的筆數
         *
         * @param count 是否再執行選取的邏輯
         */
        const handleCommonlyUsedData = (count = true) => {
            // 清除群組聯絡人選取的數據
            // 篩選的常用聯絡人
            selectedCommonlyUsed.value = listsByCommonlyUsed.value
                .filter((item) => item.isSelected)
                .map((contact) => ({
                    id: contact.name,
                    name: contact.name,
                    mobile: contact.mobile,
                    job: contact.job,
                    group: contact.group,
                    isEditing: false,
                })) || [];

            parsedData.value = selectedCommonlyUsed.value;
            if(parsedData.value.length === 0){
                addRowsToParsedData();
                selectedCommonlyUsed.value = [];
            }
            if(count){
                selectAllCommonlyUsedData(null, false);
            }
        };

        // 清除常用聯絡人選取的數據
        const resetCommonlyUsedData = () => {
            selectedCommonlyUsed.value = [];
            listsByCommonlyUsed.value.forEach((contact) => {
                contact.isSelected = false;
            });

        };

        /**
         * 聯絡人(群組)選取的導入流程
         * */
        const groupNames = ref([]);    // 群組
        const filterListsByGroup = ref([]);     // 篩選後的群組聯絡人列表
        const searchGroupListKeyword = ref(""); // 篩選的關鍵字
        const selectedGroup = ref("");          // 選取的群組
        let selectedGroupList = ref([]);        //選取群組聯絡人列表


        /**
         * 顯示篩選的群組聯絡人
         *
         * @param group
         */
        const showGroup = (group_id) => {
            selectedGroup.value = group_id;
            selectAllCommonlyUsedData(null, false);
            performFilterList();
        };

        const handleNextStep = () => {
            if (writeList.value !== "listsByManual") {
                writeList.value = "listsByManual";
                setActiveTab("listsByManual");
                return;
            }

            if (!smsContent.value) {
                Swal.fire({
                    title: "Oops...",
                    text: "您還尚未填寫發送簡訊內容，請先填寫簡訊內容！",
                    icon: "error",
                });
                return;
            }

            const smsSendData = generateSmsSendData.value;

            console.log("parsedData.value", parsedData.value);

            if (!validateParsedData()) return;

            // 第一步：確認發送資料
            Swal.fire({
                title: "請確定簡訊發送資料",
                html: `
                <p>
                  共發送 ${smsSendData.calculation.totalMessages} 通，花費點數 ${smsSendData.calculation.totalCost} 點，剩餘點數 198 點
                </p>
              `,
                showCancelButton: true,
                confirmButtonText: '下一步 <i class="fa fa-chevron-right"></i>',
                cancelButtonText: "取消",
            }).then((res) => {
                if (res.isConfirmed) {
                    // 第二步：發送設定成功
                    Swal.fire({
                        title: "簡訊發送設定成功",
                        html: `
              <p>
              共發送 ${smsSendData.calculation.totalMessages} 通，花費點數 ${smsSendData.calculation.totalCost} 點，剩餘點數 198 點
              </p>
              <p>詳細發送紀錄請至 "發送查詢" !</p>
              `,
                        icon: "success",
                        confirmButtonText: "確定",
                    });
                    resetCommonlyUsedData();
                    // resetGroupListData();
                    parsedData.value = [];
                    smsSubject.value = "";
                    smsContent.value = "";
                    writeList.value = "listsByExcel";
                }
            });

            console.log("smsSendData", smsSendData);
        };
        const mapParseData = (data) => {
            return data.map((item) => ({
                id: item.id,
                name: item["name"] || "",
                mobile: item["mobile"] || "",
                email: item["email"] || "",
                title: item["job"] || "",
                params: {
                    custom1: item["customize1"] || "",
                    custom2: item["customize2"] || "",
                    custom3: item["customize3"] || "",
                },
            }));
        };
        const generateSmsSendData = computed(() => {
            const costPerMessage = smsCount.value;
            const totalMessages = parsedData.value.length;
            const totalCost = totalMessages * smsCount.value;
            console.log("parseData", parsedData.value.length);
            console.log("總共筆數", totalMessages, "總共扣點", totalCost);
            return {
                parseData: mapParseData(parsedData.value),
                smsSubject: smsSubject.value,
                smsContent: smsContent.value,
                calculation: {
                    totalMessages,
                    totalCost,
                    costPerMessage,
                },
                schedule: {
                    sendTime: formatToCustomDate(
                        schedule.value ? selectedDatetime.value : new Date().toISOString()
                    ),
                    immediate: schedule.value ? false : true,
                },
            };
        });

        const handleScheduleChange = () => {
            if (!schedule.value) {
                selectedDatetime.value = ""; // 清空日期
            }
        };

        // 手機檢查
        const validatePhoneNum = (mobile) => /^09\d{8}$/.test(mobile);
        const validateParsedData = () => {
            if (!parsedData.value || parsedData.value.length === 0) {
                // if (parseData.value.length === 0) {
                Swal.fire({
                    title: "Oops...",
                    text: "您還尚未填寫收件者名單，請先填寫收件者名單！",
                    icon: "error",
                });
                return false;
            }

            for (let i = 0; i < parsedData.value.length; i++) {
                const item = parsedData.value[i];
                if (!validatePhoneNum(item.手機門號)) {
                    Swal.fire({
                        title: "Oops...",
                        text: "請輸入正確的手機號碼！",
                        icon: "error",
                    });
                    return false;
                }
            }
            return true;
        };

        onMounted(() => {
            getEmployeePoints();    // 取得點數
            getSaveState();
        });

        watch(searchKeyword, (newKeyword) => {
            if (!newKeyword.trim()) {
                clearTimeout(timeoutId.value);
                timeoutId.value = setTimeout(() => {
                    filteredList.value = listsByCommonlyUsed.value;
                }, 2000);
            }
        });

        const getSaveState = () => {
            updateItemState('groups', 'get', null);
            updateItemState('contacts', 'get', null);
            updateItemState('commonMessages', 'get', null);
            updateItemState('signatures', 'get', null);
            saveState.parsedData = [];
        };

        /**
         * 取得點數
         *
         * @returns {Promise<* | void>}
         */
        const getEmployeePoints = () => {
            return fetch(`/points/get_employee_points`, {
                method: "POST",
                headers: {'X-Requested-With': 'XMLHttpRequest'},
                body: new URLSearchParams({})
            }).then(res => {
                if (!res.ok)
                    return Promise.reject(res)
                return res.json();
            }).then(json => {
                points.value = json.points || 0; // 設定可用點數
                return json;
            }).catch(err => {
                console.error("錯誤:", err);
            });
        };


        return {
            points,
            smsSubject,
            smsContent,
            isLoading,
            rowsToAdd,
            smsCount,
            insertPlaceholder,
            isCollapse,
            toggleCollapse,
            saveState,
            updateItemState,
            writeList,
            uploadFile,
            handleAddBlankRows,
            letterExample,
            handleFileUpload,
            selectedCommonlyUsed,
            handleCommonlyUsedData,
            parsedData,
            listsByCommonlyUsed,
            searchKeyword,
            handleFilterList,
            filteredList,
            setActiveTab,
            handleNextStep,
            schedule,
            selectedDatetime,
            handleScheduleChange,

            // 群組
            groupNames,
            filterListsByGroup,
            searchGroupListKeyword,
            showGroup,
            selectedGroup,
            selectedGroupList,

            selectAllCommonlyUsedData,
            selectAll,
            hasGroup
        };
    },
}).mount("#sms");
